.popup-title {
  font-family: Lato Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #092c40;
  margin-top: 5px;
}

.popup-close-btn {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.input-label {
  font-family: Lato Regular;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #AABAB7;
  font-family: Lato Regular;
  margin-bottom: 8px;
}

.popup-input {
  border: 2px solid rgba(9, 44, 64, 0.1);
  border-radius: 4px;
  outline: none;
  font-family: Lato Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #092c40;
  padding: 10px;
  margin-bottom: 20px;
}

.popup-input:focus {
  border-color: #68c3d3;
}

.popup-textarea {
  border: 2px solid rgba(9, 44, 64, 0.1);
  border-radius: 4px;
  outline: none;
  font-family: Lato Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #092c40;
  padding: 10px;
  margin-bottom: 20px;
  resize: none;
}

.popup-textarea:focus {
  border-color: #68c3d3;
}

.popup-btn {
  width: 185px;
  height: 48px;
  background: #169d8f;
  border-radius: 8px;
  border: none;
  outline: none;
  font-family: Lato Medium;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  margin: 0 20px;
  cursor: pointer;
  justify-self: flex-end;
}

.card {
  background: #ffffff;
  box-shadow: 5px 5px 20px rgba(9, 19, 53, 0.08);
  border-radius: 16px;
}

.card-title {
  font-family: Lato Black;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #092c40;
  margin: 5px 0;
}

.card-description {
  font-family: Lato Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #092c40;
  opacity: 0.5;
  margin: 5px 0;
}

.card-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;

  label {
    font-family: Lato Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #092c40;
    margin-left: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.card-button {
  height: 48px;
  background: #169d8f;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  font-family: Lato Regular;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}

$text-color: #020819;
$accent-color: #40f1de;
$background-color: #fafcfd;
$selected-menu-item-color: #053149;
$submenu-item-color: #f4f9fb;
$user-icon-color: #40f1de;
$sidebar-background: #ffffff;
$border-color: #e3eef3;

* :focus-visible {
  outline: none;
}

@mixin text {
  font-size: 14px;
  color: #020819;
}

.light-text {
  @include text;
  font-family: Lato Light;
}

.regular-text {
  @include text;
  font-family: Lato Regular;
}

.medium-text {
  @include text;
  font-family: Lato Medium;
}

.bold-text {
  @include text;
  font-family: Lato Bold;
}

.semibold-text {
  @include text;
  font-family: Lato Semibold;
}

.black-text {
  @include text;
  font-family: Lato Black;
}

.role-text {
  font-size: 10px;
}

.login-text {
  @include text;
  font-family: Lato Light;
  font-size: 22px;
}

.title-text {
  @include text;
  font-size: 22px;
  font-family: Lato Black;
}

.popup-title {
  font-family: Lato Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #092c40;
  margin-top: 5px;
}

.popup-close-btn {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.label {
  color: #053149;
  font-family: Lato Light;
  font-size: 14px;
  margin-bottom: 8px;
}

textarea {
  width: 100%;
  min-height: 80px;
  border: 1px solid #d2e5ed;
  border-radius: 8px;
  color: #053149;
  font-family: Roboto Regular;
  font-size: 16px;
  outline: none;
  padding: 11px 16px 11px 16px;
  resize: none;
}

.input {
  height: 48px;
  border: 1.5px solid rgba(181, 185, 193, 0.3);
  border-radius: 16px;
  color: #053149;
  font-family: Lato Regular;  
  font-size: 16px;
  outline: none;
  padding-left: 16px;
  padding-right: 16px;
}

.input:focus {
  border: 1.5px solid #32e5c5;
}

.input::placeholder {
  opacity: 0.3;
}

.textarea {
  width: 100%;
  border: 1.5px solid rgba(181, 185, 193, 0.3);
  border-radius: 16px;
  color: #053149;
  font-family: Lato Regular;
  font-size: 16px;
  outline: none;
  padding: 11px 16px 11px 16px;
  resize: none;
}

.textarea:focus {
  border: 1px solid #32e5c5;
}

.textarea::placeholder {
  opacity: 0.3;
}

.loader {
  display: flex;
  position: absolute;
  align-items: center;
  background: #05314933;
  border-radius: 16px;
  justify-content: center;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}

.popup-input::placeholder {
  font-family: Lato Regular;
  font-size: 16px;
  color: #053149;
  opacity: 0.5;
}

.popup-input {
  border: 1px solid #d2e5ed;
  border-radius: 8px;
  outline: none;
  font-family: Lato Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #053149;
  padding: 10px;
  margin-bottom: 20px;
}

.popup-textarea {
  border: 2px solid rgba(9, 44, 64, 0.1);
  border-radius: 4px;
  outline: none;
  font-family: Lato Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #092c40;
  padding: 10px;
  margin-bottom: 20px;
  resize: none;
}

.popup-btn {
  width: 185px;
  height: 48px;
  background: #32e5c5;
  border-radius: 8px;
  border: none;
  outline: none;
  font-family: Lato Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  margin: 0 20px;
  cursor: pointer;
  justify-self: flex-end;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-end {
  align-items: flex-end;
}

.items-start {
  align-items: flex-start;
}

.item-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.icon-gray path {
  fill: #73868c;
}

hr {
  width: 100%;
  height: 1px;
  background-color: #e5e5e5;
  border: none;
  margin-bottom: 15px;
}

p {
  // padding: 0px;
  margin: 0px;
}

.flex-0-6 {
  flex: 0.6;
}

.flex-0-7 {
  flex: 0.7;
}

.flex-0-8 {
  flex: 0.8;
}

.flex-1 {
  flex: 1;
}

.flex-1-2 {
  flex: 1.2;
}

.flex-1-5 {
  flex: 1.5;
}

.flex-2 {
  flex: 2;
}

.flex-2-5 {
  flex: 2.5;
}

.flex-3 {
  flex: 3;
}

.flex-6 {
  flex: 6;
}

.col-6 {
  flex-basis: 16.6667%;
  width: 16.6667%;
}

.col-50 {
  flex-basis: 48.5%;
  width: 48.5%;
}

.col-gap-1 {
  column-gap: 4px;
}

.col-gap-2 {
  column-gap: 8px;
}

.col-gap-4 {
  column-gap: 16px;
}

.row-gap-1 {
  row-gap: 4px;
}

.row-gap-2 {
  row-gap: 8px;
}

.fw-w {
  flex-wrap: wrap;
}

.ai-c {
  align-items: center;
}

.info-text {
  font-size: 11px;
  color: #73868c;

  span {
    font-size: 11px;
  }
}

.bg-gray {
  background: #f4f7f9;
}

.color-gray {
  color: #73868c;
}

.color-white {
  color: #fff;
}

.color-green {
  color: #24cbb1;
}

.ml-4 {
  margin-left: 16px;
}

.ml-5 {
  margin-left: 20px;
}

.ml-6 {
  margin-left: 24px;
}

.ml-8 {
  margin-left: 32px;
}

.ml-10 {
  margin-left: 40px;
}

.ml-12 {
  margin-left: 48px;
}

.mr-auto {
  margin-right: auto;
}

.mt-auto {
  margin-top: auto;
}

.mr-6 {
  margin-right: 24px;
}

.mr-5 {
  margin-right: 20px;
}

.mr-10 {
  margin-right: 40px;
}
.mr-12 {
  margin-right: 48px;
}


.mr--16 {
  margin-right: 64px;
}

.mr-8 {
  margin-right: 32px;
}

.mr-7 {
  margin-right: 28px;
}

.mr-4 {
  margin-right: 16px;
}

.ml-2 {
  margin-left: 8px;
}

.ml-1 {
  margin-left: 4px;
}

.ml-3 {
  margin-left: 12px;
}

.ml-4 {
  margin-left: 16px;
}

.ml-6 {
  margin-left: 24px;
}

.mr-1 {
  margin-right: 4px;
}

.mr-2 {
  margin-right: 8px;
}

.mr-3 {
  margin-right: 12px;
}

.mr-16 {
  margin-right: 16px;
}

.mx-1 {
  margin-left: 4px;
  margin-right: 4px;
}

.mx-2 {
  margin-left: 8px;
  margin-right: 8px;
}

.mx-3 {
  margin-left: 12px;
  margin-right: 12px;
}

.mx-4 {
  margin-left: 16px;
  margin-right: 16px;
}

.mx-6 {
  margin-left: 24px;
  margin-right: 24px;
}

.mx-8 {
  margin-left: 32px;
  margin-right: 32px;
}

.mx-10 {
  margin-left: 40px;
  margin-right: 40px;
}

.mxy-6 {
  margin: 24px;
}
.mxy-1 {
  margin: 4px;
}
.mxy-2 {
  margin: 8px;
}
.mxy-3 {
  margin: 12px;
}

.mxy-05 {
  margin: 2px;
}

.mt-1 {
  margin-top: 4px;
}

.mt-3 {
  margin-top: 12px;
}

.mt-5 {
  margin-top: 20px;
}

.mt-6 {
  margin-top: 24px;
}

.mt-7 {
  margin-top: 28px;
}

.mt-2 {
  margin-top: 8px;
}

.mt-4 {
  margin-top: 16px;
}

.mt-8 {
  margin-top: 32px;
}

.mt-10 {
  margin-top: 40px;
}

.mt-12 {
  margin-top: 48px;
}

.mt-16 {
  margin-top: 64px;
}

.mt-20 {
  margin-top: 80px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 4px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-3 {
  margin-bottom: 12px;
}

.mb-4 {
  margin-bottom: 16px;
}

.mb-6 {
  margin-bottom: 24px;
}

.mb-8 {
  margin-bottom: 32px;
}

.mb-10 {
  margin-bottom: 40px;
}

.mb-20 {
  margin-bottom: 80px;
}

.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.my-3 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.my-1 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.my-4 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.my-6 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.my-8 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.justify-start {
  justify-content: start;
}

.justify-center {
  justify-content: center;
}

.spacer {
  flex: 1 1 auto;
}

.pr-1 {
  padding-right: 4px;
}

.pr-2 {
  padding-right: 8px;
}

.pr-4 {
  padding-right: 16px;
}
.pr-5 {
  padding-right: 20px;
}

.pr-6 {
  padding-right: 24px;
}

.pl-2 {
  padding-left: 8px;
}

.pl-4 {
  padding-left: 16px;
}
.pl-5 {
  padding-left: 20px;
}
.pl-6 {
  padding-left: 24px;
}

.pt-6 {
  padding-top: 24px;
}

.pt-12 {
  padding-top: 48px;
}

.pt-2 {
  padding-top: 8px;
}

.pt-3 {
  margin-top: 12px;
}

.pt-4 {
  padding-top: 16px;
}

.pt-5 {
  padding-top: 20px;
}

.pb-2 {
  padding-bottom: 8px;
}

.pb-4 {
  padding-bottom: 16px;
}

.pb-6 {
  padding-bottom: 24px;
}

.pb-8 {
  padding-bottom: 32px;
}

.pb-10 {
  padding-bottom: 40px;
}


.pb-12 {
  padding-bottom: 48px;
}

.pb-3 {
  padding-bottom: 12px;
}

.px-5 {
  padding-left: 20px;
  padding-right: 20px;
}

.px-6 {
  padding-left: 24px;
  padding-right: 24px;
}

.px-12 {
  padding-left: 48px;
  padding-right: 48px;
}

.px-3 {
  padding-left: 12px;
  padding-right: 12px;
}

.px-1 {
  padding-left: 4px;
  padding-right: 4px;
}

.px-2 {
  padding-left: 8px;
  padding-right: 8px;
}

.px-4 {
  padding-left: 16px;
  padding-right: 16px;
}

.py-1 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py-3 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.py-4 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-5 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pl-6 {
  padding-left: 24px;
}

.py-6 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.py-8 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.pxy-1 {
  padding: 4px;
}

.pxy-1-2 {
  padding: 4px 8px;
}

.pxy-2 {
  padding: 8px;
}

.pxy-3 {
  padding: 12px;
}

.pxy-4 {
  padding: 16px;
}

.pxy-6 {
  padding: 24px;
}

.pxy-12 {
  padding: 48px;
}

.pxy-2-4 {
  padding: 8px 16px;
}

.pxy-3-4 {
  padding: 12px 16px;
}

.pxy-3-8 {
  padding: 12px 24px;
}

.pxy-4-2 {
  padding: 16px 8px;
}

.font-24 {
  font-size: 24px;
}

.font-32 {
  font-size: 32px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-9 {
  font-size: 9px;
}

.border-dot {
  border: 2px dashed #d9e3ea;
}

.border-1 {
  border: 1px solid #d9e3ea;
}

.border-2-2-2-0 {
  border: 1px solid #d9e3ea;
  border-top: none;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;

}

.m-auto {
  margin: auto;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.h-content {
  height: max-content;
}

.w-50 {
  width: 50%;
}

.cursor * {
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}

.move-cursor {
  cursor: move;
}

.menu {
  border-radius: 8px;
  box-shadow: 2px 2px 15px rgba(5, 49, 73, 0.1);
  user-select: none;
}

.no-select {
  user-select: none;
}

.border-right {
  border-right: 1px solid #d2e5ed;
}

.border-top-grey {
  border-top: a(2, 8, 25, 0.1);
}

.text-wrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 40px;
  overflow: hidden;
}

.nowrap {
  white-space: nowrap;
}

.wrap {
  flex-wrap: wrap;
}

.overflow-hidden {
  overflow: hidden;
}

.justify-end {
  justify-content: flex-end;
}

.font-12 {
  font-size: 12px;
}

.font-10 {
  font-size: 10px;
}

.font-20 {
  font-size: 20px;
}

.white-text {
  color: #ffffff;
}

.light-green-text {
  color: #AABAB7;
}

.grey-text {
  color: #b5b9c1;
}

.light-grey-text {
  color: #d2e5ed;
}

.light-purple-text {
  color: #AABAB7;
}

.mid-green-text {
  color: #24CBB1;
}

.search-border {
  border: 1px solid #d2e5ed;
  border-radius: 8px;
}

.o05 {
  opacity: 0.5;
}

.o1 {
  opacity: 1;
}

.o03 {
  opacity: 0.3;
}

.radius-1 {
  border-radius: 4px;
}

.radius-2 {
  border-radius: 8px;
}

.radius-4 {
  border-radius: 8px;
}

.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: center;
}

.fw-500 {
  font-weight: 500;
}

.border-top {
  border-top: 1px solid rgba(181, 185, 193, 0.3);
}

.border-left {
  border-left: 1px solid #e3eef3;
}

.border-right {
  border-right: 1px solid rgba(181, 185, 193, 0.3);
}

.border-bottom {
  border-bottom: 1px solid #E9EAED;
}

.grey-text-o03 {
  color: rgba(115, 134, 140, 0.3);
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.green-text {
  color: #32e88b;
}

.violet-light-text {
  color: #24CBB1;
  ;
}

.violet-dark-text {
  color: #24CBB1;
}

.gap-2 {
  gap: 8px;
}

.gap-3 {
  gap: 12px;
}

.gap-4 {
  gap: 16px;
}

.gap-6 {
  gap: 24px;
}

.gap-12 {
  gap: 48px;
}

.contact-image {
  background: #ffffff;
  border: 1px solid #f6f6f6;
  border-radius: 4px;
  min-height: 24px;
  min-width: 24px;
  max-height: 24px;
  max-width: 24px;
}

.one-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.menu-text {
  max-width: 168px;
}

.nav-arrow {
  height: 16px;
  width: 16px;
}

.fit-content {
  height: fit-content;
}

.crm-menu {
  position: absolute;
  width: 188px;
  right: 30px;
  top: -5px;

  .crm-menu-item {
    height: 40px;
  }

  .crm-menu-item:hover {
    background: #f6f6f6;
    border-radius: 8px;
  }
}

.popup {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(11, 22, 35, 0.49);
  top: 0;
  left: 0;
  user-select: none;
  outline: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;

  .popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    background: #ffffff;
    border: 1px solid rgba(240, 246, 249, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 6px 40px rgba(108, 129, 140, 0.1);
    border-radius: 16px;
    outline: 0;
    margin-top: 64px;
    padding: 48px;
    position: relative;
  }
}

.cancel-btn {
  width: 152px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #24CBB1;
  ;
  border-radius: 16px;
  cursor: pointer;
}

.done-btn {
  width: 152px;
  height: 48px;
  border: 1px solid #24cbb1;
  background: #24cbb1;
  border-radius: 16px;
  cursor: pointer;
}

.disable-btn {
  width: 152px;
  height: 48px;
  background: #e9eaed;
  border-radius: 16px;
  cursor: pointer;
  color: white;
}

.grab-cursor * {
  cursor: grabbing;
}

.grab-cursor {
  cursor: grabbing;
}

.move-cursor * {
  cursor: move;
}

.move-cursor {
  cursor: move;
}

.default-cursor * {
  cursor: default;
}

.default-cursor {
  cursor: default;
}

#curved-corner-bottomleft,
#curved-corner-bottomright,
#curved-corner-topleft,
#curved-corner-topright {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: relative;
}

#curved-corner-bottomleft:before,
#curved-corner-bottomright:before,
#curved-corner-topleft:before,
#curved-corner-topright:before {
  content: "";
  display: block;
  width: 200%;
  height: 200%;
  position: absolute;
  border-radius: 50%;
}

#curved-corner-bottomleft:before {
  bottom: 0;
  left: 0;
  box-shadow: -50px 50px 0 0 red;
}

#curved-corner-bottomright:before {
  bottom: 0;
  right: 0;
  box-shadow: 50px 50px 0 0 red;
}

#curved-corner-topleft:before {
  top: 0;
  left: 0;
  box-shadow: -50px -50px 0 0 red;
}

#curved-corner-topright:before {
  top: 0;
  right: 0;
  box-shadow: 50px -50px 0 0 red;
}

.nav-arrow {
  width: 15px;
}

.select,
.input-select {
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  background: transparent;
  background-image: url(../images/dropdown.svg);
  background-repeat: no-repeat;
  background-position-x: calc(100% - 19px);
  background-position-y: 19px;
  padding-right: 38px;

  option {
    color: black;
    padding: 8px;
  }

  option:hover {
    background: #f6f6f6 !important;
  }
}

.input-select {
  background-image: none;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 16px;

  .date-selector {
    top: 65px;
    left: 0;

    .selected-day {
      background: #24cbb1;
    }
  }
}

.select:required:invalid {
  color: #AABAB7;
}

.divider {
  height: 1px;
  background: #DCDDE0;
  width: 100%;
}

.new-version-popup {
  top: 0;
  left: 0;
  z-index: 30;
  width: 100%;
  height: 100%;
  background: rgba(4, 32, 48, 0.8);

  .nw-refresh-view{
    height: 430px;
    width: 520px;
  
    .nv-refresh-btn {
      height: 48px;
      width: 90%;
      background: #40f1de;
      border-radius: 8px;
    }
  }
}

.br-2 {
  border-radius: 8px;
}

.medium-btn {
  height: 32px;
  border-radius: 10px;
}

* :focus-visible {
  outline: none;
}

* ::-webkit-scrollbar {
  width: 0;
  height: 0px;
}

* {
  scrollbar-width: none;
}

// .one-line {
//   display: -webkit-box;
//   -webkit-line-clamp: 1;
//   -webkit-box-orient: vertical;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }

.two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.three-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fit-content {
  height: fit-content;
}