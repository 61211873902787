.arrow-down {
    transition: transform 300ms;
  }

.arrow-up {
    transform: rotate(180deg);
}

.ret-tx-grid {
    display: grid;
    grid-template-columns: minmax(100px, 1.2fr) minmax(100px, 0.8fr) minmax(100px, 1fr)  minmax(100px, 1fr) minmax(100px, 1.5fr);
}

.ret-tx {
    max-height: 352px;
    overflow-y: scroll;
}

.tx-date-time {
    padding-left: 72px;
}

.retirement-tx {
    transition: max-height 300ms linear 0s;
}

.camp-ret-item {
    overflow-y: hidden;
}

.camp-ret-menu  {
    right: 4px;
    top: 18px;
}

.header-menu-items {
    right: 20px;
    min-width: 80px;
}