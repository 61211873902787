.checkbox-wrapper {
  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 0.15em;
    border: 0.15em solid rgba(181, 185, 193, 0.5);
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    margin: 0;
  }
  input.checked {
    background-color: #24CBB1;
    border: none;
    position: relative;
  }
  input.checked::before {
    content: url(../../assets/images/check.svg);
    transform: scale(.5);
    color: #fff;
    position: absolute;
    right: 1px;
    top: 1px;
  }
  input[type="checkbox"]:disabled {
    border-color: #c0c0c0;
    background-color: #c0c0c0;
  }
}
