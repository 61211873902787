.ci-grid {
    display: grid;
    grid-template-columns: minmax(100px, 1.7fr) minmax(100px, 1.7fr)  minmax(100px, 0.8fr)  minmax(100px, 1fr) minmax(150px, 1.5fr)  minmax(100px, 1.4fr) minmax(80px, 1.5fr) minmax(50px, 0.9fr) minmax(50px, 0.8fr) minmax(50px, 0.3fr);
}

.ci-header {
    background-color: #FAFAFA;
}

.ci-list {
    max-height: calc(100vh - 170px);
    overflow-y: scroll;
}