.main-page {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    background: #FBFBFB;

    .main {
        display: flex;
        flex: 1;

        .content {
            display: flex;
            flex: 1;
        }
    }
}