.cp-country {
    height: 18px;
    background: #F6F6F6;
    border-radius: 6px;
}

.cp-org {
    height: 18px;
    background: #020819;
    border-radius: 6px;
}

.campaign-retire {
    height: calc(100vh - 345px);
    overflow-y: scroll;
    margin-bottom: 20px;
}