@import '../common.scss';

.organisation-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 24px;
    row-gap: 24px;

    @media only screen and (max-width: 1800px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 500px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.menu-btn:hover {
    background: rgba(36, 203, 177, 0.1);
    border-radius: 4px;
}