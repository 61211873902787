.date-selectors {
  width: 375px;
  top: 40px;
  left: -155px;
  z-index: 11;

  &::before {
    content: '';
    background: white;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%) rotate(45deg);
    position: absolute;
    left: 50%;
    top: 0px;
  }

  .selector-label {
    width: 75px;
  }

  .delete-date-btn {
    width: 24px;
    height: 24px;
    background: #f4f7f9;
    border-radius: 8px;
  }
}