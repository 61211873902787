.templates {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    row-gap: 24px;
}

.collection-main-details {
    overflow-y: scroll;
    max-height: calc(100vh - 250px);

    .textarea-large {
        min-height: 112px;
    }
}