.template-details-l {
  width: 350px;

  .template-details-edit {
    height: calc(100vh - 300px);
    overflow: scroll;

    .input {
      padding: 14px 16px;
    }

    .mc-select {
      height: 100%;
      width: 100%;

      .selected-icon,
      .option-icon {
        height: 18px;
      }
    }

    .error {
      border-color: red;
      .mc-select {
        .organisations-border {
      border-color: red !important;
          
        }
      }
    }
  }
}

.template-image {
  width: 306px;
  border-radius: 16px;
  object-fit: cover;
}

.template-details-info {
  max-height: calc(100vh - 170px);
  overflow-y: scroll;
}
