.arrow-down {
    transition: transform 400ms;
    transform: rotate(0);
  }

  .arrow-up {
    transform: rotate(180deg);
  }

  .ci-consigneds {
    overflow-y: auto;
    transition: height 400ms;
  }

  .ci-card:hover {
    background: #FAFAFA;
  }