.templates-cards  {
  height: calc(100vh - 125px);
  overflow-y: scroll;

  .template-grid {
    display: grid;
    grid-template-columns: minmax(200px, 2fr) minmax(200px, 3fr) minmax(130px, 1fr) minmax(150px, 1.5fr);

    .template-item {
        height: 72px;
    }

    .templates-template-image {
      height: 64px;
      width: 64px;
      object-fit: cover;
      border-radius: 12px;
    }
  }
}