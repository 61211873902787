.organisation-filter {
    top: 45px;
    left: -70px;
    width: 312px;
    z-index: 5;

    &::before {
        content: '';
        background: white;
        width: 10px;
        height: 10px;
        transform: translate(-50%, -50%) rotate(45deg);
        position: absolute;
        left: 50%;
        top: 0px;
      }
}

.input-arrow {
    height: 40px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    transition: 0.3s;
    .dropdown-icon {
      transition: 0.3s;
    }
    .open {
      transform: rotate(-90deg);
    }
  }

  .organisations-border {
    border: 1px solid rgba(181, 185, 193, 0.3);
    border-radius: 12px;
  }

  .of-options {
    width: 264px;
    max-height: 260px;
    overflow-y: scroll;
    transition: height 200ms linear 0s;
  }

  .org-name-input {
    font-family: Lato Regular;
    font-size: 16px;
    color: #020819;
    border: none;
    width: 100%;
  }

  .selected-values {
    flex-wrap: wrap;
    transition: height 200ms linear 0s;

    .selected-value {
        background: #F6F6F6;
        border-radius: 8px;

        .remove-btn {
            height: 24px;
            width: 24px;
            background: rgba(181, 185, 193, 0.3);
            border-radius: 6px;
        }
    }
  }

  .all-border {
    border-bottom: 1px solid rgba(9, 44, 64, 0.04);
  }

  .clear-text {
    color: #AABAB7;
  }