.sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 260px;
    min-width: 260px;
    background: #000000;
    border-radius: 16px;
    margin: 24px 0 24px 24px;
    max-height: calc(100vh - 50px);
    overflow-y: scroll;

    .sidebar-top {
        display: flex;
        flex-direction: column;
        position: relative;
        outline: 0;

        .menu-logo {
            width: 32;
        }

        .user-info {
            background: #1A2123;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;

            .user-menu {
                width: 240px;
                position: absolute;
                top: 70px;
                left: 10px;
                z-index: 2;

                .um-item:hover {
                    background: #F6F6F6;
                    border-radius: 8px;
                }
            }
        }   

        .organisation-info {
            background: #1A2123;
            border-top: 1px solid #10F3CF;

        }

        .org-logo {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 40px;
            min-height: 40px;
            background: #F9CC75;
            border-radius: 4px;

            label {
                font-family: Roboto Medium;
                font-weight: bold;
                font-size: 16px;
                line-height: 19px;
                color: #FFFFFF;
            }
        }

        .org-name {
            font-family: Roboto Medium;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            color: #092C40;
            margin: 0 10px;
        }
        
        .menu-organisations {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background: #F8F8F8;
            border-radius: 8px;
            padding: 10px;
            margin: 0 15px;
            margin-bottom: 20px;
            
            .mo-left  {
                display: flex;
                flex-direction: row;
                align-items: center;

            }

           .org-arrows {
               margin-right: 10px;
               cursor: pointer;
           }

        }
   
        .menu-organisations-open {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .org-venues {
            position: absolute;
            display: flex;
            flex-direction: column;
            background: #F8F8F8;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            top: 120px;
            left: 15px;
            width: 200px;
            z-index: 2;
            overflow: hidden;
            transition: height 200ms linear 0s;
            box-shadow: 0px 10px 20px rgba(9, 19, 53, 0.14);

            .org-venue * {
                cursor: pointer;
            }

            .org-venue {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 0 10px;
                cursor: pointer;
                margin: 7px 0;

                .venue-left {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .org-logo {
                        background: #9D7EF8;
                    }

                    .org-details {
                        display: flex;
                        flex-direction: column;

                    }
                }

                img {
                    padding: 0 5px;
                    cursor: pointer;
                }
            }

            .add-new-venue * {
                cursor: pointer;
            }

            .add-new-venue {
                display: flex;
                flex-direction: row;
                align-items: center;
                border-top: 1px solid rgba(9, 44, 64, 0.1);
                margin-top: 5px;
                padding: 20px 0;
                padding-left: 10px;
                user-select: none;
                cursor: pointer;

                img {
                    margin-left: 10px;
                }

                label {
                    font-family: Roboto Medium;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    color: #169D8F;
                    margin-left: 10px;
                }
            }
        }
    }

    .logout {
        background: #1A2123;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
    }

    .sidebar-bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 10px;
        padding: 10px;
        background: #F8F8F8;
        border-radius: 8px;
        position: relative;
        outline: none;

        .sb-left {
            display: flex;
            flex-direction: row;
            align-items: center;

            .user-logo {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 40px;
                min-height: 40px;
                background: #51AFD8;
                border-radius: 4px;
    
                label {
                    font-family: Roboto Medium;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 19px;
                    color: #FFFFFF;
                }
            }
    
            .user-name-role {
                display: flex;
                flex-direction: column;
                margin-left: 10px;
    
                .user-name {
                    font-family: Roboto Medium;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 19px;
                    color: #092C40;
                }
    
                .user-role {
                    font-family: Roboto Regular;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 14px;
                    color: #092C40;
                    opacity: 0.5;
                }
            }
        }

        .user-menu-btn {
            padding: 0 5px;
            cursor: pointer;
        }

        .user-menu {
            display: flex;
            flex-direction: column;
            position: absolute;
            background: #F8F8F8;
            border-radius: 8px;
            z-index: 2;
            bottom: 70px;
            left: 0px;
            width: 210px;
            overflow: hidden;
            transition: height 200ms linear 0s;
            box-shadow: 0px 10px 20px rgba(9, 19, 53, 0.14);

            .user-menu-item * {
                cursor: pointer;
            }

            .user-menu-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 45px;
                cursor: pointer;

                .umu-left {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    
                    img {
                        min-width: 24px;
                        min-height: 24px;
                        object-fit: scale-down;
                        margin-left: 15px;
                        margin-right: 5px;
                    }
    
                    label {
                        font-family: Roboto Medium;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        color: #092C40;
                    }
                }
            }

            .user-menu-item-selected {
                background: #FFFFFF;
                justify-content: space-between;

                img {
                    margin-right: 15px;
                }
            }

            .exit-merchant {
                border-top: 1px solid rgba(9, 44, 64, 0.1);
            }

            .invite-user {
                border-top: 1px solid rgba(9, 44, 64, 0.1);
                border-bottom: 1px solid rgba(9, 44, 64, 0.1);

                label {
                    color: #169D8F;
                }
            }

        }
    }

  
}

.organisation-menu {
    position: absolute;
    width: 244px;
    left: 8px;
    top: 60px;

    .menu-org {
        height: 40px;
        border-radius: 8px;
    }

    .menu-org:hover {
        background: #F6F6F6;
    }
}