.campaign-details-comp {
    height: calc(100vh - 255px);
}

.capaign-edit-btn {
    height: 32px;
    width: 96px;
    background: rgba(36, 203, 177, 0.1);
    border-radius: 10px;
    
    svg {
        path {
        fill: #24cbb1;
        }
    }
}