.campaign-nfts {
  height: calc(100vh - 130px);
  overflow-y: scroll;
  margin-bottom: 20px;

  .scroll-wrapper {
    height: calc(100vh - 200px);
    overflow: auto;

    .infinite-scroll-component__outerdiv {
      height: calc(100% - 20px);
    }
  }
}

.campaign-mint-progress {
  overflow-y: hidden;
  transition: height 400ms;
}

.campaign-mint-progress-item {
  display: grid;
  grid-template-columns: minmax(280px, 3fr) minmax(375px, 4fr) minmax(
      100px,
      0.3fr
    );

  .mint-progress-image {
    height: 32px;
    width: 32px;
    border-radius: 8px;
    aspect-ratio: 1;
  }

  .checked-icon {
    border-radius: 50%;
  }

  .mint-progress-name {
    width: calc(100% - 32px);
  }
}

.campaign-nft-top {
  background: #f6f6f6;

  .campaign-nft-color {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    background: #dae6eb;
    border-radius: 6px;
  }

  .arrow-down {
    transition: transform 400ms;
    transform: rotate(0);
  }

  .arrow-up {
    transform: rotate(-90deg);
  }
}

.campaign-nft-image {
  width: 56px;
  height: 56px;
  border-radius: 12px;
}

.nft-lifecycle {
  min-height: 16px;
  padding: 2px 8px;
  border-radius: 6px;
  right: 16px;
}
