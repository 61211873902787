.edit-retirement-project-description {
    max-width: 200px;
}

.edit-retirement-input {
    height: 48px;
    border: 1.5px solid rgba(181, 185, 193, 0.3);
    border-radius: 16px;
    padding: 0 16px;
    min-width: 0px;
    font-family: 'Lato Regular';
    font-size: 16px;
    color: #020819;
    width: 100%;
}

.edit-retirement-input::-webkit-outer-spin-button,
.edit-retirement-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; 
}

.edit-retirement-input-container {
    height: 48px;
    border: 1.5px solid rgba(181, 185, 193, 0.3);
    border-radius: 16px;
    padding: 0 16px;
    width: 200px;
}

.edit-retirement-input-text {
    border: none;
    min-width: 0px;
    font-family: 'Lato Regular';
    font-size: 16px;
    color: #020819;
}

.selected-name {
    // width: max-content;
}

.mc-select {
    width: 100%;
}