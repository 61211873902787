.campaign-list-nfts {
    height: calc(100vh - 275px);
    margin-bottom: 20px;
    overflow-y: hidden;
}

.tiers-nfts-list {
    height: calc(100vh - 275px);
    overflow-y: scroll;

    .infinite-scroll-component__outerdiv {
        height: calc(100% - 10px);
      }
}