.collections-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 24px;
  row-gap: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 120px);
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media only screen and (max-width: 1800px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (max-width: 1400px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }

  
}

.collection {

  .collection-description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;  
  }

  .collection-detail {
    max-width: 310px;
    min-height: 346px;
    background: white;
    border-radius: 16px;
    padding: 10px;

    .collection-image {
      width: 100%;
      height: 160px;
      border-radius: 12px;
      object-fit: cover;
    }

    .platform {
      padding: 4px 8px;
      border-radius: 8px;
      color: white;
      background: #020819;
      font-weight: 500;
      font-size: 12px;
      position: absolute;
      top: 10px;
      left: 10px;
    }

    .content-box {
      padding: 8px;
      position: relative;

      .collection-menu {
        width: 190px;
        height: auto;
        border-radius: 16px;
        position: absolute;
        right: -190px;
        top: 0;
        z-index: 10;
        padding: 8px;
        box-shadow: 0px 10px 20px rgba(9, 19, 53, 0.16);

        .menu-title {
          font-weight: 700;
          padding: 8px 10px;
          text-transform: uppercase;
          margin-top: 8px;
        }

        .sub-menu-title {
          text-transform: none;
          font-weight: 400;
          padding: 10px 10px;
          font-size: 17px;
          color: black;

          &:hover {
            background: #F6F6F6;
            border-radius: 8px;
          }
        }

        .border-1 {
          border: 1px solid #020819;
          margin: 8px -8px;
          opacity: 0.1;
        }
      }
    }
  }
}

.collections-grid::-webkit-scrollbar {
  display: none;
}

.retirement-cards, .supporter-cards, .inventory-cards {
  overflow-y: auto;
  max-height: calc(100vh - 120px);
  padding-bottom: 16px;
  font-family: "Lato Regular", serif;

  .retirement-card {

    .card-box {
      padding: 16px;
    }

    .box-first {
      padding: 24px;
    }

    .account-box {
      width: 240px;
    }
  }
}

.retirement-cards::-webkit-scrollbar {
  display: none;
}

.supporter-cards::-webkit-scrollbar {
  display: none;
}

.sdg-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  row-gap: 16px;
  overflow-y: auto;
  padding-right: 16px;
  max-height: calc(100vh - 220px);
}

.sdg-grid::-webkit-scrollbar {
  display: none;
}

.collection-integration {
  background: rgb(170, 186, 183, 0.1);
  border-radius: 12px;
  height: 32px;
  min-width: 88px;
}