

.merchandise-nfts-list {
    height: calc(100vh - 260px);
    overflow-y: scroll;

    .infinite-scroll-component__outerdiv {
        height: calc(100% - 10px);

      }
}