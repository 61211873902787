
.tiers-nfts-list {
    height: calc(100vh - 395px);
    overflow-y: scroll;

    .infinite-scroll-component__outerdiv {
        height: calc(100% - 10px);
      }
}


.camp-nft-grid {
    display: grid;
    grid-template-columns: minmax(100px, 2fr) minmax(100px, 1.2fr) minmax(100px, 1fr)  minmax(100px, 1.2fr) minmax(100px, 1fr) minmax(100px, 1.2fr) minmax(100px, 0.8fr);
}

.camp-nft-header {
    background-color: #FAFAFA;
}   