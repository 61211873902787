.menu-item {
    display: flex;
    flex-direction: column;
    user-select: none;

    .mi-item * {
        cursor: pointer;
    }

    .menu-item-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 56px;

        .mi-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 16px;
            margin: 8px 16px;
            width: 100%;
            height: 48px;
            cursor: pointer;
    
            .mii-left {
                display: flex;
                align-items: center;
    
                .mi-icon {
                    
                }   
            }
    
            .mi-arrow {
                transition-duration: 300ms;
                transition-property: transform;
            }           
        }

        .mi-item-selected {
            background: #1A2123;
            border-radius: 16px;

            .mi-name {
                color: #ffffff;
            }

            .mi-arrow {
                path {
                    stroke: #FFFFFF;
                }
            }
        }
    }

    .menu-subitems * {
        cursor: pointer;
    }

    .menu-subitems {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        overflow:hidden;
        transition: height 300ms linear 0s;
        margin-left: 27.5px;

        .subitem-container {
            display: flex;
            flex-direction: row;

            .vertical-line {
                position: absolute;
                left: 0px;
                height: 100%;
                width: 1px;
                background-color: #24cbb1;
            }

            .subitem {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 48px;
                width: 100%;
                margin: 0px 16px;

                .si-icon {
                    margin-left: 10px;
                }
               
                .si-name {
                    font-family: Roboto Medium;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 16px;
                    color: #10F3CF;
                    margin-left: 10px;
                }
            }
        }

        .last-subitem {
            .vertical-line {
                height: 23px;
                top: 0px;
            }
        }

        .subitem-selected {
            .subitem-name {
                background: #1A2123;
                border-radius: 13px;
                height: 40px;
            }
            .subitem {
                            
            }
        }
    }

    .menu-subitems-hide {
        height: 0;
        overflow:hidden;
        transition: height 300ms linear 0s;
    }

    .mi-arrow-down {
        transform: rotate(90deg);
    }
}
