.add-retirement-popup {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color:  rgba(11, 22, 35, 0.49);
  top: 0;
  left: 0;
  user-select: none;
  outline: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;

  .add-retirement-popup-content {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid rgba(240, 246, 249, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 6px 40px rgba(108, 129, 140, 0.1);
    border-radius: 16px;
    outline: 0;
    width: 860px;
    padding: 20px;
    margin-top: 24px;
    position: relative;

    .popup-title {
      font-weight: 700;
      color: black;
    }

    .selector {
      height: 48px;
      border: 1.5px solid rgba(181, 185, 193, 0.3);
      border-radius: 16px;
      color: #053149;
      font-family: Lato Regular;
      font-size: 16px;
      outline: none;
      padding-left: 16px;
      padding-right: 16px;
    }

    .add-registry-btn {
      background: rgba(36, 203, 177, 0.1);
      border-radius: 10px;
      height: 32px;
      width: 100%;
      margin: 16px;
    }

    .add-registry {
      width: 100%;
    }

  }

  .add-ret-proj-d {
    max-width: 400px;
  }

  .carbon-details-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .inactive-input {
    border: 1px solid #B5B9C180;
    border-radius: 16px;
    height: 48px;
    padding: 0 16px;
    background-color:#FBFBFB;
  }
}
