.search-input {
    min-width: 224px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid rgba(181, 185, 193, 0.3);
    border-radius: 16px;

    input {
        border: none;
        width: 100%;
    }
}

.add-btn {
    width: 136px;
    height: 40px;
    background: rgba(36, 203, 177, 0.1);
    border-radius: 12px;
    user-select: none;
}

.organisation-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 24px;
    row-gap: 24px;

    @media only screen and (max-width: 1800px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 500px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.venue-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 24px;
    row-gap: 24px;

    @media only screen and (max-width: 1500px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 700px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.account-bottom {
    background: rgba(246, 246, 246, 1);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.org-name {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.organisation {

    .organisation-details {
        overflow: hidden;
        
        .org-description {
            overflow: hidden;
 

            span {
                height: 34px;
                min-height: 0;
                min-width: 0;
                max-height: 34px;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                -webkit-box-orient: vertical;

            }
        }
    }
}

.org-image {
    width: 160px;
    height: 160px;
    border-radius: 8px;
}
