.mc-add {
  top: 50px;
  width: 100%;
  border-radius: 12px;
  z-index: 5;
  box-sizing: border-box;

  .mc-add-input {
    height: 40px;
    border: 1px solid rgba(181, 185, 193, 0.3);
    border-radius: 12px;
    padding: 0 16px;
    min-width: 0px;
    font-family: 'Lato Regular';
    font-size: 16px;
    color: #020819;
    width: 100%;
  }
}

.mc-options {
    top: 38px;
    width: 100%;
    max-height: 200px;
    // transition: height 200ms linear 0s;
    border-radius: 12px;
    z-index: 5;
    box-sizing: border-box;

    .mc-options-list {
      overflow-y: scroll;

    }

    .mc-option-item:hover {
        background: #E9FAF7;
        border-radius: 8px;
    }

    .mc-option-item {
        height: 32px;
        min-height: 32px;
        overflow-x: hidden;

        label {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
  }

  .mc-select {
    height: 32px;
    border-radius: 12px;
  }

  .selected-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .dd-search-cont {
    border-radius: 12px;
    border: 1px solid rgba(181, 185, 193, 0.50);
    height: 32px;

    input {
      border: none;
      font-size: 16px;
    }
  }