.time-toggle {
  background: #F6F6F6;
  border-radius:8px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .time {
    height: 24px;
    width: 72px;
    text-transform: uppercase;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.selected {
      background: white;
      box-shadow: 3px 3px 10px rgba(5, 49, 73, 0.1);
      border-radius: 6px;
    }
  }

  &.toggle-white {
    background: #ffffff;

    .time.selected {
      background: #f6f6f6;
      box-shadow: none;
    }
  }
}
