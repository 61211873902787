.upload-btn {
    left: 115px;
    top: 120px;
    z-index: 2;

    img {
        width: 40px;
    }
}

.image-container {
    display: flex;

    .image-actions {
        top: 16px;
        right: 16px;
        display: none;
    
        .image-action {
            width: 24px;
            height: 24px;
            background: #020819;
            border-radius: 8px; 
        }
    }
}

.image-container:hover {
    .image-actions {
        display: flex;
    }
}