.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: #000000;

    .login {
        width: 100%;
        z-index: 2;

        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100vh;


        .login-form {
            display: flex;
            flex-direction: column;
            align-items: center;
    
            img {
                width: 224px;
                height: 136px;
            }
    
            .subtitle {
                font-family: Roboto Bold;
                font-style: normal;
                font-size: 24px;
                line-height: 28px;
                color: #FFFFFF;
            }
    
            form {
                display: flex;
                flex-direction: column;
                margin-top: 20px;
    
                .input-label {
                    align-self: flex-start;
                    font-family: Roboto Light;
                    font-size: 12px;
                    line-height: 14px;
                    color: #FFFFFF;
                    margin-bottom: 5px;
                    margin-top: 20px;
                }
    
                input {
                    outline: 0;
                    font-size: 16px;
                    height: 48px;
                    background: #FFFFFF;
                    border: 1.5px solid rgba(181, 185, 193, 0.5);
                    border-radius: 8px;
                    padding: 0 16px;
                    font-family: Roboto Regular;
                    font-size: 16px;
                    color: #020819;

                    &:focus {
                        border-color:#10F3CF;
                    }
                }

                .error-border {
                    border-color: #D93E38;
                }

                .show-password {
                    position: absolute;
                    width: 24px;
                    height: 24px;
                    right: 16px;
                    top: 12px;
                    cursor: pointer;
                }
    
                input[type=submit] {
                    width: 100%;
                    margin-top: 40px;
                    margin-bottom: 24px;
                    height: 56px;
                    width: 330px;
                    border-radius: 16px;
                    align-self: center;
                    background-color: #217E70;
                    border: none;
                    outline: 0;
                    cursor: pointer;
    
                    font-family: Roboto Medium;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 16px;
                    color: #FFFFFF;
                }
    
                a {
                    font-family: Roboto Medium;
                    font-size: 16px;
                    color: #10F3CF;
                    text-decoration: none;
                    align-self: center;
                }
            }

            .input-container {
                display: flex;
                flex-direction: column;
                position: relative;
            }

            .error-msg {
                display: flex;
                flex-direction: row;
                align-items: center;
                position: absolute;
                top: 50px;

                .error-icon {
                    min-width: 12px;
                    min-height: 12px;
                    width: 12px;
                    height: 12px;
                    margin: 0px;
                }

                label {
                    font-family: Roboto Medium;
                    font-style: normal;
                    font-size: 12px;
                    color: #D93E38;
                    margin-left: 5px;
                }
            }
        }
    }

    .background-logo {
        position: absolute;
        right: 0;
        bottom: 0;
    }
}