@import '../../assets/css/common.scss';

.user-deleted-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color:  rgba(11, 22, 35, 0.49);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;

    .user-deleted-popup-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        overflow: hidden;
        background: #FFFFFF;
        border: 1px solid rgba(240, 246, 249, 0.5);
        box-sizing: border-box;
        box-shadow: 0px 6px 40px rgba(108, 129, 140, 0.1);
        border-radius: 16px;
        outline: 0;
        width: 504px;
        height: 204px;
        margin-top: 100px;

        .ud-title {
            font-family: Roboto Bold;
            font-size: 24px;
            line-height: 28px;
            color: #053149;
        }

        button {
            width: 168px;
            height: 48px;
            background: #32E5C5;
            color: #FFFFFF;
            border-radius: 8px;
            font-family: Roboto Bold;
            font-size: 16px;
            border: none;
            outline: none;
            cursor: pointer;
        }
    }
}
