.campaign-project-menu  {
    right: 24px;
    top: 27px;
}

.campaign-project-image {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 12px;
}

.campaign-nft-details-image {
    width: 306px;
    border-radius: 16px;
}


.campaign-project-retire-grid{
    display: grid;
    grid-template-columns: minmax(0, 2fr) minmax(0, 2fr) minmax(0, 1.2fr) minmax(0, 2.5fr);
}

.nft-lifecycle {
    top: 16px;
    right: 56px;
}

.retirement-input {
    border: 1px solid rgba(181, 185, 193, 0.3);
    border-radius: 8px;
    max-width: 94px;
    padding: 0 12px;
    height: 24px;

    input {
        border: none;
        max-width: 70px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; 
}
}

.create-view-nft-btn {
    width: 80px;
    height: 32px;
    border-radius: 10px;
}

.campaign-project-retire-item {
    height: 72px;
    margin-top: 4px;
}