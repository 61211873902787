.ci-retirements {
    max-height: calc(100vh - 240px);
    overflow-y: scroll;
}

.ci-ret-grid {
    display: grid;
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr)  minmax(100px, 1fr) minmax(100px, 1fr) ;
}

.ci-ret-header {
    background-color: #FAFAFA;
}
