.product-image {
    width: 64px;
    height: 64px;
    border-radius: 12px;
    object-fit: contain;
    box-sizing: content-box;
}

.product-desc {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .product-item {
    height: 80px;
  }

  .product-items {
    height: 88px;
  }