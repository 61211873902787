.acp-actions {
    display: flex;
    align-items: center;
    gap: 16px;
  
    button {
      width: 96px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #24CBB1;
      font-weight: 700;
      background: none;
      border-radius: 10px;
      color: #24CBB1;
      font-size: 14px;
      cursor: pointer;
      transition: all 0.2s ease-in;
    }
  
    button.save-action {
      background: #24CBB1;
      color: white;
    }
  
    button:hover {
      opacity: 0.8;
    }
  }

  .mc-select { 
    flex: 1;
  }

  .add-campaign-project-image {
    width: 88px;
    height: 88px;
    object-fit: cover;
    border-radius: 8px;
  }

  .campaign-template-dd {
    width: 224px;
  }