.edit-delete-menu-items {
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(9, 19, 53, 0.16);
    border-radius: 16px;
    width: 188px;
    z-index: 4;
    top: -10px;
    right: 30px;

    .header-menu-item:hover {
        background: #F6F6F6;
        border-radius: 8px;

        label {
        }
    }
}

.edit-delete-menu {
    width: 24px;
    height: 24px;
}

.edit-delete-menu:hover {
    background: #F6F6F6;
    border-radius: 8px;
    width: 24px;
    height: 24px;
}