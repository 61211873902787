
.switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 48px;
    height: 24px;
    background: #d2e5ed;
    box-shadow: inset 0px 0px 8px rgba(5, 49, 73, 0.1);
    border-radius: 20px;

    &:hover {
      background: #e5ebef;
    }

    .selector {
      min-width: 20px;
      min-height: 20px;
      background: #ffffff;
      box-shadow: 1px 0px 5px rgba(5, 49, 73, 0.2);
      border-radius: 50%;
      margin: 0 2px;
      transition: transform 400ms;
    }
  }

  .switch-selected {
    background: #40f1de;
    box-shadow: inset 0px 0px 8px rgba(5, 49, 73, 0.1);

    &:hover {
      background: #40f1de;
    }

    .selector {
      transform: translate(24px);
    }
  }