.projects-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  overflow-y: auto;
  max-height: calc(100vh - 100px);
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: 0 12px 24px 12px;

  @media only screen and (max-width: 1800px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (max-width: 1400px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .project {
    padding-bottom: 24px;
    margin: 12px;
    overflow: hidden;
    height: 346px;
    box-shadow: 0px 6px 20px rgba(9, 19, 53, 0.08);
    border-radius: 16px;
    padding: 8px;

    .project-description {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;  
    }

    .project-image {
      width: 100%;
      height: 160px;
      border-radius: 12px;
      object-fit: cover;
    }

    .location {
      padding: 4px 8px;
      border-radius: 8px;
      color: black;
      background: white;
      font-weight: 500;
      font-size: 12px;
      position: absolute;
      top: 10px;
      left: 10px;
    }

    .project-organisations {
      flex-wrap: wrap;
      position: absolute;
      bottom: 0;

      .project-organisation {
        background: #020819;
        border-radius: 6px;
        height: 16px;
      }
    }

    .content-box {
      padding: 8px;
      position: relative;

      .project-menu {
        width: 190px;
        height: auto;
        border-radius: 16px;
        position: absolute;
        right: -190px;
        top: 0;
        z-index: 10;
        padding: 8px;
        box-shadow: 0px 10px 20px rgba(9, 19, 53, 0.16);

        .menu-title {
          font-weight: 700;
          padding: 8px 10px;
          text-transform: uppercase;
          margin-top: 8px;
        }

        .sub-menu-title {
          text-transform: none;
          font-weight: 400;
          padding: 10px 10px;
          font-size: 17px;
          color: black;

          &:hover {
            background: #F6F6F6;
            border-radius: 8px;
          }
        }

        .border-1 {
          border: 1px solid #020819;
          margin: 8px -8px;
          opacity: 0.1;
        }
      }
    }
  }
}

.projects-grid::-webkit-scrollbar {
  display: none;
}

.retirement-cards, .supporter-cards, .inventory-cards {
  overflow-y: auto;
  height: calc(100vh - 120px);
  padding-bottom: 16px;
  font-family: "Lato Regular", serif;

  .retirement-card {

    .blocks {
      width: 150px;
    }
    .card-box {
      padding: 16px;
    }

    .box-first {
      padding: 24px;
    }

    .account-box {
      width: 240px;
    }
  }
}

.retirement-cards::-webkit-scrollbar {
  display: none;
}

.supporter-cards::-webkit-scrollbar {
  display: none;
}

.sdg-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  row-gap: 16px;
  overflow-y: auto;
  padding-right: 16px;
  max-height: calc(100vh - 220px);
}

.sdg-grid::-webkit-scrollbar {
  display: none;
}
