.tooltipModal-card {
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(9, 19, 53, 0.16);
  min-height: 200px;
  min-width: 376px;
  position: absolute;
  border-radius: 16px;
  z-index: 10;
  left: 50%;
  right: 0;
  transform: translate(-50%, 22px);

  .triangle {
    margin: auto;
    top: -17px;
    position: absolute;
    left: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 17.3px 10px;
    border-color: transparent transparent white transparent;
  }

  .title {
    font-weight: 500;
    color: black;
  }

  .action {
    padding: 6px 14px;
    font-weight: 500;
    border-radius: 8px;
    border: 1px solid #e9eaed;
    cursor: pointer;
    background: none;
  }
}
