.template {
    display: grid;
    grid-template-columns: minmax(100px, 1fr) minmax(200px, 2fr) minmax(100px, 1fr);
}

.collection-template-image {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    object-fit: cover;
}

.collection-template-description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
}