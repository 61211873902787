.activate-page {
    position: relative;
    background: #000000;
    .background-logo {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .logo {
        width: 224px;
        height: 136px;
    }

    .fp-content {
        width: 100%;
        z-index: 2;
        height: 100vh;

        .subtitle {
            max-width: 360px;
            text-align: center;
        }

        .otp-content {
            margin-top: 40px;

            .otp-input {
              margin: 0px 8px;
      
              .input-style {
                width: 50px;
                height: 50px;
                border: unset;
                text-align: center;
                background: #ffffff;
                // box-shadow: 5px 5px 30px rgb(5 49 73 / 10%);
                border-radius: 8px;
                font-size: 16px;
                color: #053149;
                font-family: Roboto Regular;
      
                :focus-visible {
                  outline: 0px;
                }
      
                ::-webkit-input-placeholder {
                  opacity: 0.3;
                  color: #053149;
                }
              }
      
              .error-style {
                border: 1px solid #ff5b5b;
              }
            }
          }

        .input-label {
            align-self: flex-start;
            font-family: Roboto Light;
            font-size: 12px;
            line-height: 14px;
            color: #FFFFFF;
            margin-bottom: 5px;
            margin-top: 20px;
        }

        input {
            outline: 0;
            font-size: 16px;
            height: 48px;
            background: #FFFFFF;
            border: 1.5px solid rgba(181, 185, 193, 0.5);
            border-radius: 8px;
            padding: 0 16px;
            font-family: Roboto Regular;
            font-size: 16px;
            color: #020819;
            width: 330px;

            &:focus {
                border-color:#32E88B;
            }
        }

        button {
            margin-top: 40px;
            margin-bottom: 24px;
            height: 56px;
            width: 330px;
            border-radius: 16px;
            background-color: #32E88B;
            border: none;
            outline: 0;
            cursor: pointer;

            font-family: Roboto Medium;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            color: #FFFFFF;
        }
    }

    .show-password {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 16px;
        top: 35px;
        cursor: pointer;
    }

    .otp-error {
        width: 380px;
        background: #FB3535;
        border-radius: 4px;

        img {
            width: 16px;
            height: 16px;
        }
    }

    .password-error {
        width: 330px;
        background: #FB3535;
        border-radius: 4px;

        img {
            width: 16px;
            height: 16px;
        }
    }

    .re-send {
        user-select: none;

        img {
            width: 24px;
            height: 24px;
        }
    }
}