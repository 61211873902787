.tier-details {
    .tier-details-l {
        width: 350px;
        min-height: 300px;
    }

    .priority-input {
        height: 48px;
        border: 1.5px solid rgba(181, 185, 193, 0.3);
        border-radius: 16px;
        color: #053149;
        font-family: Lato Regular;
        font-size: 16px;
        outline: none;
        padding-left: 16px;
        padding-right: 16px;
        width: 150px;
      }

    .tier-color {
        height: 48px;
        width: 100%;
        background: #F5D45E;
        border-radius: 16px;
    }
}

.tier-details-color {
    height: 16px;
    width: 16px;
    min-height: 16px;
    min-width: 16px;
    border-radius: 6px;
}

.grey-box {
    background: #F6F6F6;
    border-radius: 8px;
}

.tier-details-content {
    height: calc(100vh - 190px);
}

.edit-tier-color {
    width: 100%;
    height: 48px;
    border-radius: 16px;
}