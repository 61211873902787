.retirement-details {
  .retirement-details-l {
    width: 350px;

    .retirement-details-edit {
      max-height: calc(100vh - 315px);
      overflow: scroll;

      .input {
        padding: 14px 16px;
      }

      .mc-select {
        width: 100%;
        height: 100%;

        .selected-icon,
        .option-icon {
          height: 18px;
        }
      }

      .end-date-selector {
        .date-selector {
          right: 0;
          left: unset;
        }
      }
    }
  }

  .social-btn {
    background: #f6f6f6;
    border-radius: 10px;
    width: 112px;
  }
}
.ci-icon {
  height: 18px;
}


.retirement-grid1 {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.retirement-grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.carbon-retirement {
  max-height: calc(100vh - 110px);
}


.retirement-state {
  height: 16px;
  background: #FBBF66;
  border-radius: 4px;
  width: fit-content;
}


.retirement-details-actions {
  display: none;
  right: 24px;
  top: 16px;
}

.retirement-url:hover {
  .retirement-details-actions  {
      display: flex;
  }
}

.ret-project-image {
  width: 306px;
  border-radius: 16px;
  object-fit: cover;
}