.nft-card-grid {
  display: grid;
  grid-template-columns: minmax(100px, 2fr) minmax(80px, 1fr)  minmax(80px, 1.5fr);
  min-height: 108px;
  height: 108px;

  .ncg-item {
    height: 100px;
  }

    .nft-image {
      width: 85px;
      height: 85px;
      border-radius: 12px;
      object-fit: contain;
    }

    .nft-desc {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .nft-lifecycle {
        min-height: 16px;
        padding: 2px 8px;
        border-radius: 6px;
        right: 16px;
    }

    .nft-card-token {
      max-width: 150px;
    }
}