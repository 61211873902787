.mc-options {
    top: 38px;
    width: 100%;
    overflow-y: scroll;
    max-height: 200px;
    transition: height 200ms linear 0s;
    border-radius: 12px;
    z-index: 5;
    box-sizing: border-box;

    .carbon-option-item:hover {
        background: #E9FAF7;
        border-radius: 8px;
    }

    .carbon-option-item {
        height: 120px;
        min-height: 120px;
        overflow-x: hidden;

        label {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
  }

  .mc-select {
    height: 32px;
    border-radius: 12px;
  }

  .carbon-selected-name {
    width: 160px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .carbon-dd-blocks {
    width: 96px;
    height: 56px;
    background: #F6F6F6;
    border-radius: 8px;
  }