.accounts {
    overflow-y: scroll;
    max-height: calc(100vh - 120px);
}

.account-grid {
    display: grid;
    grid-template-columns: 64px minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1.5fr) minmax(100px, 0.7fr)  minmax(100px, 0.7fr) minmax(100px, 0.7fr);
}

.account-header {
    background-color: #FAFAFA;
}


.account-rr { 
    height: calc(100vh - 375px);
    overflow-y: scroll;
}

.account-list-camp {
    height: calc(100vh - 165px);
    overflow-y: hidden;
}

.account-g--list {
    height: calc(100vh - 100px);
    overflow-y: scroll;

    .infinite-scroll-component__outerdiv {
        height: calc(100% - 10px);
      }
}

.account-g--grid {
    display: grid;
    grid-template-columns: minmax(100px, 1.2fr)  minmax(100px, 0.8fr) minmax(100px, 1fr) minmax(100px, 0.8fr) minmax(100px, 1.5fr);
}

.account-g--list {
    max-height: calc(100vh - 190px);
    overflow-y: scroll;
}

.masquerade-btn:hover {
    .masquerade  {
        path {
            fill: #24CBB1;
        }
    }
}