.tiers-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @media only screen and (max-width: 1500px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 900px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.tier-color {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    background: #F6F6F6;
    border-radius: 6px;
}