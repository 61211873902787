.fade-appear {
    opacity: 0;
    transform: translate(0px, 10px);
    transition: all 400ms ease-in;
  }
  
  .fade-appear-active {
    opacity: 1;
    transform: translate(0px, 0px);
    transition: all 400ms ease-in;
  }
  
  .fade-appear-done {
    opacity: 1;
    transform: unset;
    transition: all 400ms ease-in;
  }