@import '../../assets/css/common.scss';

.delete-user-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color:  rgba(11, 22, 35, 0.49);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;

    .delete-user-popup-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        overflow: hidden;
        background: #FFFFFF;
        border: 1px solid rgba(240, 246, 249, 0.5);
        box-sizing: border-box;
        box-shadow: 0px 6px 40px rgba(108, 129, 140, 0.1);
        border-radius: 16px;
        outline: 0;
        width: 504px;
        height: 232px;
        margin-top: 100px;
        padding: 30px 80px;

        .dup-title {
            font-family: Roboto Bold;
            font-size: 24px;
            color: #053149;
            text-align: center;
        }

        .dup-actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 35px;

            button {
                width: 168px;
                height: 48px;
                border: 2px solid #32E5C5;
                background: #FFFFFF;
                border-radius: 8px;
                font-family: Roboto Bold;
                font-size: 16px;
                color: #053149;
                cursor: pointer;
                margin: 0 10px;
            }

            .delete-action {
                background: #FF5B5B;
                border: 2px solid #FF5B5B;
                color: #FFFFFF;
            }
        }
    }
}