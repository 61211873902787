@font-face {
  font-family: 'Roboto Light';
  src: local('Roboto Light'), url(./assets//fonts/Roboto-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Regular';
  src: local('Roboto Regular'), url(./assets/fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Medium';
  src: local('Roboto Medium'), url(./assets/fonts/Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Bold';
  src: local('Roboto Bold'), url(./assets/fonts/Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Black';
  src: local('Roboto Black'), url(./assets/fonts/Roboto-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato Light';
  src: local('Lato Light'), url(./assets//fonts/Lato-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato Regular';
  src: local('Lato Regular'), url(./assets/fonts/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato Medium';
  src: local('Lato Medium'), url(./assets/fonts/Lato-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato Semibold';
  src: local('Lato Semibold'), url(./assets/fonts/Lato-Semibold.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato Bold';
  src: local('Lato Bold'), url(./assets/fonts/Lato-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato Black';
  src: local('Lato Black'), url(./assets/fonts/Lato-Black.ttf) format('truetype');
}
* {
  box-sizing: border-box;
}