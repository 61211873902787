.add-campaign-popup {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color:  rgba(11, 22, 35, 0.49);
  top: 0;
  left: 0;
  user-select: none;
  outline: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;

  .add-campaign-popup-content {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid rgba(240, 246, 249, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 6px 40px rgba(108, 129, 140, 0.1);
    border-radius: 16px;
    outline: 0;
    width: 724px;
    padding: 24px;
    margin-top: 64px;
    position: relative;

    .popup-title {
      font-weight: 700;
      color: black;
    }
  }
} 