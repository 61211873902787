.tx-filter-options{
  width: 272px;
  top: 30px;
  left: -95px;
  z-index: 11;

  &::before {
    content: '';
    background: white;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%) rotate(45deg);
    position: absolute;
    left: 50%;
    top: 0px;
  }
}

.tx-filter-list {
  max-height: 214px;
  overflow-y: scroll;
}