.sales-chart {
    .custom-tooltip {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #ffffff;
        box-shadow: 1px 1px 10px rgba(5, 49, 73, 0.2);
        border-radius: 4px;
        padding: 10px 20px;
        width: 247px;
    
        .tooltip-content {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin: 5px 0;
        }
      }
    
      .sale-selectors {
        min-height: 75px;
      }
    
      .sale-selector {
        background: #f4f7f9;
        border-radius: 4px;
    
        .sale-selector-dot {
          min-width: 9px;
          min-height: 9px;
          width: 9px;
          height: 9px;
          border-radius: 50%;
          background: #40f1de;
          border: 1px solid #ffffff;
        }
      }
      
}