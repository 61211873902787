.nft-card {

    .card-left {
      padding: 8px 24px 8px 8px;
      border-right: 1px solid #E9EAED;
      width: 40%;

      .nft-image {
        width: 85px;
        height: 85px;
        border-radius: 12px;
        object-fit: contain;
      }

      .nft-desc {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .card-middle {
      padding: 8px 24px 8px 24px;
      border-right: 1px solid #E9EAED;
      width: 20%;
    }

    .card-right {
      padding: 8px 24px 8px 24px;
      width: 40%;
    }

    .nft-lifecycle {
        min-height: 16px;
        padding: 2px 8px;
        border-radius: 6px;
    }
  }