.campaign-nft-nfts {
    overflow-y: scroll;   
    transition: height 400ms;
}

.campaign-nft-nft {
    display: grid;
    grid-template-columns: minmax(100px, 2fr) minmax(100px, 1.2fr) minmax(100px, 1fr)  minmax(100px, 1.2fr) minmax(100px, 1fr) minmax(100px, 1.2fr) minmax(100px, 0.8fr);
}

.campaign-nft-top {
    background: #F6F6F6;
    
    .campaign-nft-color {
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
        background: #DAE6EB;
        border-radius: 6px;
    }

    .arrow-down {
        transition: transform 400ms;
        transform: rotate(0);
    }
    
    .arrow-up {
        transform: rotate(-90deg);
    }
}

.campaign-nft-s-image {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    object-fit: cover;
}

.nft-lifecycle {
    min-height: 16px;
    height: 16px;
    padding: 2px 8px;
    border-radius: 6px;
    right: 16px;
}

.open-icon {
    // display: none;
}

.campaign-nft-nft:hover  {
    .open-icon {
        display: block;
    }
}