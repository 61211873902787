.campaign-leaderboards {
    height: calc(100vh - 275px);
    overflow-y: scroll;
    margin-bottom: 20px;

    .scroll-wrapper {
        height: calc(100vh - 295px);
        overflow: auto;
    
        .infinite-scroll-component__outerdiv {
          height: calc(100% - 10px);
        }
     }
}

.campaign-leaderboard {
    overflow-y: hidden; 
    transition: height 400ms;
    .leaderboard-item {
        display: grid;
        grid-template-columns: minmax(156px, 1fr) minmax(185px, 2fr) minmax(265px, 2fr) minmax(124px, 1fr);

        .leaderboard-rank {
            padding: 4px 8px;
            background: rgba(181, 185, 193, 0.3);
            border-radius: 6px;
        }
    }
}