.mc-color-picker {
    z-index: 5;
    top: 60px;
    left: -40px;
    width: 224px;

    &::before {
        content: '';
        background: white;
        width: 10px;
        height: 10px;
        transform: translate(-50%, -50%) rotate(45deg);
        position: absolute;
        left: 50%;
        top: 0px;
      }

    .cp-saturation {
        width: 168px;
        height: 168px;
        .saturation-white {
            // border-radius: 8px;
        }
    }

    .cp-hue {
        width: 16px;
        height: 168px;

        .hue-vertical {
            right: -8px;
            border-radius: 100px;
        }
    }
  
    .custom-color {
        border: 1.5px solid rgba(181, 185, 193, 0.3);
        border-radius: 8px;
        width: 88px;
        height: 24px;
    }

    .default-color {
        width: 16px;
        height: 16px;
        border-radius: 4px;
        border: 2px solid #1A2123;
    }
}