.tx-grid {
    display: grid;
    grid-template-columns: minmax(100px, 1.2fr)  minmax(100px, 0.5fr) minmax(100px, 0.6fr) minmax(100px, 2fr) minmax(100px, 1fr)  minmax(100px, 0.8fr) minmax(80px, 1.5fr) minmax(50px, 1.5fr)
}


.tx-header {
    background-color: #FAFAFA;
}


.tx-list {
    height: calc(100vh - 170px);
    overflow-y: scroll;

    .infinite-scroll-component__outerdiv {
        height: calc(100% - 20px);
      }
}

.txs-search-input {
    height: 32px;
    width: 224px;
    border-radius: 12px;
}

.txs-search-input ::placeholder {
    opacity: 0.5;
}

.tx-dates-btn {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 8px;
    border: 1px solid rgba(181, 185, 193, 0.50);
    background: #FFFFFF;
}

.tx-lifecycle-selector {
    height: 32px;
    background: rgb(170, 186, 183, 0.1);
    border-radius: 12px;
  }