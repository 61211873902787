.nft-search-input {
  width: 250px;
}

.filter-icon {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  border: 1px solid #E9EAED;
  background: #ffffff;
}

.nft-cards {
  overflow-y: auto;
  height: calc(100vh - 120px);
  padding-bottom: 16px;
  font-family: "Lato Regular", serif;
}

.nft-cards::-webkit-scrollbar {
  display: none;
}

.lifecycle-selector {
  width: 88px;
  height: 32px;
  background: rgb(170, 186, 183, 0.1);
  border-radius: 12px;
}