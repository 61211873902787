.photos {
    display: flex;
    padding: 32px;

    .photos-left {
        display: flex;
        flex: 1;

        img {
            width: 100%;
            height: fit-content;
            object-fit: contain;
        }

        video {
            width: 100%;
            height: fit-content;
            object-fit: contain;
        }
    }

    .photos-right {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 32px;

        .view-selectors {
            display: flex;
            margin-bottom: 16px;
        
            .view-selector * {
                cursor: pointer;
            }

            .view-selector {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1;
                height: 36px;
                cursor: pointer;
            }
        }

        input {
            margin-bottom: 16px;
            padding: 8px;
            font-size: 16px;
        }

        .actions {
            display: flex;
            align-items: center;

            button {
                height: 36px;
                flex: 1;
            }

            :not(:first-child) {
                margin-left: 16px;
            }
        }

        .creatives {
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            height: calc(100vh - 400px);
            margin-top: 24px;

            .header {
                border-bottom: 1px solid grey;
                padding-bottom: 8px;
            }

            .creative * {
                cursor: pointer;
            }

            .creative {
                display: grid;
                grid-template-columns: 2fr 1fr 1fr 1fr;
                padding: 12px;
                cursor: pointer;
            }

            .selected-creative {
                background: lightgrey;
            }
        }
        
        .bv-image-placeholder {
            display: flex;
            align-items: center;
        }
    }

    .usages {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        position: relative;
        user-select: none;

        .selected-usage {
            display: flex;
            background-color: lightgray;
            padding: 12px;
            border-radius: 8px;
        }

        .su-options {
            position: absolute;
            background: #FFFFFF;
            box-shadow: 2px 2px 15px rgba(5, 49, 73, 0.05);
            border-radius: 8px;
            top: 50px;

            .su-option {
                display: flex;
                padding: 8px;
                cursor: pointer;
            }

            .su-option * {
                cursor: pointer;
            }

            .su-option:hover {
                background: lightgray;
            }
        }
    }

    .new-group {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        label {
            margin-bottom: 4px;
        }
    }

      .file-select-input {
            display: block;
        }
}