.ret-grid {
    display: grid;
    grid-template-columns: minmax(100px, 1fr) minmax(50px, 0.6fr)  minmax(100px, 1.2fr) minmax(50px, 1fr) minmax(50px, 1.4fr) minmax(50px, 0.5fr);
}

.ret-header {
    background-color: #FAFAFA;
}

.ret-list {
    max-height: calc(100vh - 165px);
    overflow-y: scroll;
}   