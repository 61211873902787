.retirement-card-grid {
    display: grid;
    grid-template-columns: 0.6fr 1fr 0.8fr 2.2fr;

    .retirement-actions {
        display: none;
        left: 100px;
        top: 22px;
    }

    .retirement-state {
        height: 16px;
        background: #FBBF66;
        border-radius: 4px;
        top: 8px;
        right: 16px;
    }
}

.retirement-card-grid:hover {
    .retirement-actions {
        display: flex;
    }
}

.ret-card:hover {
    background: #FAFAFA;
  }