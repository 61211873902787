.template-collections {
    height: calc(100vh - 190px);
    overflow-y: scroll;

    .template-collections-grid {
        display: grid;
        grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr);

        .tc-image {
            height: 48px;
            width: 48px;
            object-fit: cover;
            border-radius: 12px;
        }
    }
}

.tc-integration-img {
    height: 16px;
}