.campaigns {
    max-height: calc(100vh - 160px);
    overflow-y: scroll;
}

.campaigns-ret-grid {
    display: grid;
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 1.3fr) minmax(100px, 0.6fr)  minmax(100px, 0.6fr) minmax(100px, 0.7fr) minmax(100px, 0.7fr);
}

.campaigns-ret-header {
    background-color: #FAFAFA;
}


.campaigns-card:hover {
    background: #FAFAFA;
  }