.product-cards {
  overflow-y: auto;
  height: calc(100vh - 130px);
  padding-bottom: 16px;
  font-family: "Lato Regular", serif;

  .nft-card {

    .card-left {
      padding: 8px 24px 8px 8px;
      border-right: 1px solid #E9EAED;
      width: 65%;

      .nft-image {
        width: 85px;
        height: 85px;
        border-radius: 12px;
      }

      .nft-desc {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .card-middle {
      padding: 8px 24px 8px 24px;
      border-right: 1px solid #E9EAED;
      width: 25%;
    }

    .card-right {
      padding: 8px 24px 8px 24px;
      width: 10%;
    }
  }
}

.delete-icon {
  width: 24px;
  height: 24px;
  border-radius: 8px;
  background: #f6f6f6;
}

.add-filter-btn {
  height: 48px;
  border: 1px solid #07A38A;
  border-radius: 16px;
}
