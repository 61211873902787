.template-attributes {
    height: calc(100vh - 170px);
    overflow-y: scroll;
}

.template-attributes-edit {
    padding: 16px;
    .add-new-attribute {
        background: rgba(246, 246, 246, 0.5);
        border: 1px dashed rgba(181, 185, 193, 0.3);
        border-radius: 12px;
        padding: 22px;
      }
    
      .attribute-edit-item {
        background: #F6F6F6;
        border-radius: 16px;
        padding: 12px 16px;
        margin-bottom: 16px;

        .input {
            height: 40px;
            border-radius: 12px;
            font-size: 14px;

            &::placeholder {
                opacity: 0.5;
            }
          }

          .mc-select {
            .organisations-border {
              background-color: white !important;
            }
          }

          .remove-icon {
            path {
              fill: #fb3535;
            }
          }
      }
}