.add-achievement-popup {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color:  rgba(11, 22, 35, 0.49);
  top: 0;
  left: 0;
  user-select: none;
  outline: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;

  .add-achievement-popup-content {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid rgba(240, 246, 249, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 6px 40px rgba(108, 129, 140, 0.1);
    border-radius: 16px;
    outline: 0;
    width: 424px;
    padding: 48px;
    margin-top: 48px;
    position: relative;

    .popup-title {
      font-weight: 700;
      color: black;
    }

    .period-items {
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
      column-gap: 8px;
     
      .period-item {
        height: 32px;
        border-radius: 16px;
        background-color: #F6F6F6;
        border: 1px solid #F6F6F6;
      }

      .period-item-selected {
        border-color: #24CBB1;
      }
    }
 
  }
} 
