.campaign-nft {

    .campaign-nft-l {
        width: 350px;
    }

    .social-btn {
        background: #F6F6F6;
        border-radius: 10px;
        width: 112px;
    }
}
.ci-icon {
    height: 18px;
}

.mint-btn {
    width: 96px;
    height: 32px    ;
    background: rgba(36, 203, 177, 0.1);
    border-radius: 10px;

    label {
        color: #24CBB1;
    }
}

.retirement-project-image {
    width: 48px;
    height: 48px;
    border-radius: 12px;
}

.retirement-project-description {
    max-width: 250px;
}

.hidden-action {
    display: none;
}

.hidden-action-container:hover {
    .hidden-action {
        display: block;
    }
}

.cn-header {
    height: 73px;
}