.template-media {
    .template_image {
        border-radius: 16px;
        width: 100%;
        object-fit: cover;
    }
    
    video {
        border-radius: 16px;
        width: 100%;
        object-fit: cover;
    }

    .template-image-menu {
        top: 8px;
        right: 8px;
    }
}

.creative_type {
    width: 32px;
    height: 32px;
    left: 8px;
    bottom: 8px;
}

.template-uploading {
    width: 100%;
    height: 100%;
    min-height: 150px;
    background-color: #F6F6F6;
    border-radius: 16px;
}