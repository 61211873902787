.campaign-project-menuu  {
    right: 24px;
    top: 10px;
}

.campaign-project-image {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 12px;
}


.campaign-project-grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
}

.project-nft-lifecycle {
    top: 16px;
    right: 56px;
    min-height: 16px;
    padding: 2px 8px;
    border-radius: 6px;
}

.cp-retirements {
    background: #FBFBFB;
    overflow-y: hidden;
    transition: height 0.3s;


    .cp-registry-url {
        max-width: 215px;
    }

    .cp-retirement-info {

    }

    .cp-retirement-nft {
        width: 163px;
        min-width: 163px;
    }

    .hidden-action {
        right: -40px;
    }
}

.cp-blockchain {
    width: 170px;
}

.retirements-arrow {
    height: 40px;
    display: flex;
    align-items: center;
    transition: 0.3s;

    .dropdown-icon {
        transition: 0.3s;
      }
   
    .open {
        transform: rotate(-180deg);
    }
}