.project-info {
    width: 354px;
}

.nft-details-image {
    width: 306px;
}

.product-info-image {
    width: 306px;
}

.project-details {
    flex: 2;
  

    .pd-top {
        height: 72px;
        min-height: 72px;
    }

    .pd-details {
        overflow-y: scroll;
        max-height: calc(100vh - 190px);
    }
}

.project-details-image {
    width: 306px;
    border-radius: 12px;
    object-fit: cover;
}

.project-details-menu {
    width: 188px;
    position: absolute;
    top: -10px;
    right: 30px;
    z-index: 10;
 
    .project-details-menu-item:hover {
        background: #F6F6F6;
        border-radius: 8px;
    }
}

.carbon-item {
    display: grid;
    grid-template-columns: 1fr 1.5fr 2fr 2fr;
    height: 72px;

    .carbon-item-center {
        height: 56px;
    }
}

.carbon-actions {
    right: 0;
    top: 0;
    display: none;
}

.carbon-item:hover {
    .carbon-actions {
        display: flex;
    }
}

.dg-card {
    background: #F6F6F6;
    padding: 8px 20px 8px 8px;
    border-radius: 16px;
}

.sdg-icon {
    width: 56px;
    height: 56px;
    background-repeat: no-repeat;
    border-radius: 6px;
}

.sdg-name {
    width: 100%;
    font-weight: 500;
}

.details-info-map {
    height: 480px;
    border: 2px solid #FFFFFF;
    border-radius: 16px;
    margin-top: 32px;
    margin: 24px;
}


.media-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 24px;
    row-gap: 24px;
    overflow-y: auto;
    max-height: calc(100vh - 220px);

    @media only screen and (max-width: 1800px) {
    grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: 1400px) {
    grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
    }


    .add-media {
        background: #F6F6F6;
        border: 1px dashed rgba(181, 185, 193, 0.3);
        border-radius: 16px;
        height: 100%;
        min-height: 150px;

        .add-media-btn {
            height: 32px;
            width: 32px;
            background: #24CBB1;
            border-radius: 50%;
        }
    }

    .project-media {
        img {
            border-radius: 16px;
            width: 100%;
            object-fit: cover;
            height: 152px;
        }
        
        video {
            border-radius: 16px;
            width: 100%;
            object-fit: cover;
            height: 152px;
        }
    }

    .edit-image {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(2, 8, 25, 0.5);
        border-radius: 16px;
        height: 152px;
        width: 100%;

        .edit-image-actions {
            flex: 1;
            align-items: flex-end;

            .edit-image-action {
                width: 32px;
                height: 32px;
                background: #FFFFFF;
                border-radius: 10px;
                img {
                    height: 24px;
                    width: 24px;
                }
            }
        }
    }
}

.media-grid::-webkit-scrollbar {
    display: none;
}

.add-new-btn {
    width: 112px;
    height: 32px;
    background: rgba(36, 203, 177, 0.1);
    border-radius: 10px;
    user-select: none;
}


.edit-btn {
    width: 88px;
    height: 32px;
    background: rgba(36, 203, 177, 0.1);
    border-radius: 10px;
    user-select: none;
}
.add-media-group-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color:  rgba(11, 22, 35, 0.49);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;

    .add-media-group-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        background: #FFFFFF;
        box-shadow: 5px 5px 30px rgba(43, 7, 74, 0.1);
        border-radius: 24px;
        border-radius: 16px;
        outline: 0;
        width: 424px;
        height: 302px;
        margin-top: 64px;
        padding: 40px 48px;
        position: relative;

        .close-btn {
            height: 40px;
            width: 40px;
            background: #F6F6F6;
            border-radius: 16px;
        }

        .dup-title {
            font-family: Roboto Medium;
            font-style: normal;
            font-size: 24px;
            line-height: 28px;
            color: #092C40;

            span {
                font-family: Roboto Black;
                color: #169D8F;
            }
        }

        .dup-actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 40px;

            button {
                width: 152px;
                height: 48px;
                border: 1px solid #24CBB1;
                border-radius: 16px;
                font-family: Roboto Bold;
                font-size: 16px;
                color: #24CBB1;
                outline: none;
                cursor: pointer;
                background-color: #FFFFFF;
            }

            .add-action {
                background: rgba(181, 185, 193, 0.3);
                color: #FFFFFF;
                border: none;
            }
        }
    }

    .delete-media {
        width: 450px;
        height: 260px;
    }
}

.file-select-input {
    display: none;
}

.details-grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    column-gap: 40px;
    row-gap: 40px;

    @media only screen and (max-width: 500px) {
        grid-template-columns: repeat(1, 1fr);
    }

    .details-column-right {
        width: 336px;

        .details-info-right {
            align-items: center;
            justify-content: space-between;
            gap: 24px;

            .input {
                width: inherit;
                min-width: 100px;
            }
        }

    }
}

.carbon-items {
    overflow-y: scroll;
    max-height: calc(100vh - 240px);
}

.project-leaderboard {
    display: grid;
    grid-template-columns: 1fr 2fr 3fr 1.5fr;
    height: 72px;

    .project-leaderboard-item {
        height: 64px;

        .pl-rank {
            width: 24px;
            height: 24px;
            background: rgba(181, 185, 193, 0.3);
            border-radius: 6px;
        }
    }
}

.nav-btn {
    background: #FBFBFB;
}

.enable-switch-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .switch {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 48px;
      height: 24px;
      background: #d2e5ed;
      box-shadow: inset 0px 0px 8px rgba(5, 49, 73, 0.1);
      border-radius: 20px;

      &:hover {
        background: #e5ebef;
      }

      .selector {
        min-width: 20px;
        min-height: 20px;
        background: #ffffff;
        box-shadow: 1px 0px 5px rgba(5, 49, 73, 0.2);
        border-radius: 50%;
        margin: 0 2px;
        transition: transform 400ms;
      }
    }

    .switch-selected {
      background: #40f1de;
      box-shadow: inset 0px 0px 8px rgba(5, 49, 73, 0.1);

      &:hover {
        background: #40f1de;
      }

      .selector {
        transform: translate(24px);
      }
    }
  }