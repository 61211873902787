.header-menu {
    width: 32px;
    height: 32px;
    border-radius: 8px;

    .header-menu-items {
        background: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(9, 19, 53, 0.16);
        border-radius: 16px;
        width: 188px;
        z-index: 4;
        top: -10px;
        right: 35px;

        .header-menu-item:hover {
            background: #F6F6F6;
            border-radius: 8px;

            label {
            }
        }
    }
}

.header-menu:hover {
    background: #F6F6F6;
}

.total-amount {
    height: 24px;
    background: #F6F6F6;
    border-radius: 8px;
}

.action-header {
    height: 72px;

    .action-btn {
        padding: 8px 10px;
        background: rgba(36, 203, 177, 0.1);
        border-radius: 10px;
    }
}