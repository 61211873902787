.campaign-list-tx {
    height: calc(100vh - 255px);
    overflow-y: hidden;
}

.tx-campaign-list {
    height: calc(100vh - 530px);
    overflow-y: scroll;

    .infinite-scroll-component__outerdiv {
        height: calc(100% - 10px);
      }
}

.tx-campaign-grid {
    display: grid;
    grid-template-columns: minmax(100px, 1.2fr)  minmax(100px, 0.8fr) minmax(100px, 1fr) minmax(100px, 0.8fr) minmax(100px, 1.5fr);
}

.tx-campaign-header {
    background-color: #FBFBFB;
}

.tx-campaign-list {
    max-height: calc(100vh - 480px);
    overflow-y: scroll;
}

.tx-projects {
    overflow-x: scroll;
    width: 200px;

    .tx-project {
        width: 216px;
        min-width: 216px;
        background: #FBFBFB;
        border-radius: 16px;
        border: 1px solid #FBFBFB;
    }
}

.selected-tx-project {
    border-color: #24CBB1;
    .tx-total-kg {
        color: #24CBB1;
    }
}