.campaign-retirements {
    height: calc(100vh - 255px);
}

.camp-ret-grid {
    display: grid;
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 0.8fr) minmax(100px, 0.7fr)  minmax(100px, 1fr) minmax(100px, 0.6fr) minmax(100px, 0.6fr) minmax(100px, 0.5fr) minmax(100px, 0.9fr);
}

.camp-ret-header {
    background-color: #FAFAFA;
}

.nft-lifecycle {
    min-height: 16px;
    padding: 2px 8px;
    border-radius: 6px;
    right: 16px;
  }

.camp-retirements { 
    height: calc(100vh - 375px);
    overflow-y: scroll;
}
.action-btn {
    padding: 8px 10px;
    background: rgba(36, 203, 177, 0.1);
    border-radius: 10px;
}

.campaign-list-camp {
    height: calc(100vh - 255px);
    overflow-y: hidden;
}

.camp-campaign-list {
    height: calc(100vh - 400px);
    overflow-y: scroll;

    .infinite-scroll-component__outerdiv {
        height: calc(100% - 10px);
      }
}

.camp-campaign-grid {
    display: grid;
    grid-template-columns: minmax(100px, 1.2fr)  minmax(100px, 0.8fr) minmax(100px, 1fr) minmax(100px, 0.8fr) minmax(100px, 1.5fr);
}

.camp-campaign-list {
    max-height: calc(100vh - 400px);
    overflow-y: scroll;
}