@import '../../common.scss';

.invite-user-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color:  rgba(11, 22, 35, 0.49);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .invite-user-popup-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        background: #FFFFFF;
        border: 1px solid rgba(240, 246, 249, 0.5);
        box-sizing: border-box;
        box-shadow: 0px 6px 40px rgba(108, 129, 140, 0.1);
        border-radius: 16px;
        outline: 0;
        width: 500px;
        height: 670px;
        padding: 20px 100px;
        position: relative;

        .popup-title {
            margin-top: 10px;
            margin-bottom: 30px;
        }

        .popup-btn {
            margin: 10px 0px; 
            align-self: center;   
        }
    
        .iu-dropdown {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            position: relative;
            border: 2px solid rgba(9, 44, 64, 0.1);
            border-radius: 4px;
            margin-bottom: 20px;
            cursor: pointer;
            outline: none;

            label {
                width: 100%;
                min-height: 35px;
                font-family: Roboto Medium;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: #092C40;
                padding: 10px 15px;
                cursor: pointer;
            }

            .arrow {
                margin-right: 15px;
                transition-duration: 500ms;
                transition-property: transform;
                cursor: pointer;
            }

            .arrow-up {
                transform: rotate(180deg);
            }

            .iu-options {
                position: absolute;
                display: flex;
                flex-direction: column;
                background: #F8F8F8;
                border-radius: 4px;
                z-index: 3;
                top: 40px;
                width: 100%;
                overflow-y: scroll;
                transition: height 200ms linear 0s;

                label {
                    font-family: Roboto Medium;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    color: #092C40;
                    padding: 10px 15px;
                    white-space:nowrap;
                    text-overflow:ellipsis;
                    overflow: hidden;
                }

                label:hover {
                    background: rgba(9, 44, 64, 0.04);
                }
            }
        }
    }
}