.inventory-details {
  .inventory-details-l {
    width: 350px;
  }

  .inventory-details-l-content {
    max-height: calc(100vh - 190px);
    overflow-y: scroll;
  }

  .social-btn {
    background: #f6f6f6;
    border-radius: 10px;
    width: 112px;
  }
}
.ci-icon {
  height: 18px;
}

.ci-project-image {
  width: 306px;
  border-radius: 16px;
  object-fit: cover;
}