.top-merchants {
    margin: 12px;
    padding: 24px 0;
    padding-right: 5px;

    .merchants {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        overflow-y: scroll;
        max-height: calc(100vh - 350px);
        padding: 0 12px;

        .merchant {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 10px 0;

            .m-left {
                display: flex;
                flex-direction: row;

                .m-number {
                    width: 25px;
                    height: 25px;
                    background: rgba(9, 44, 64, 0.04);
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    label {
                        font-family: Roboto Medium;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 14px;
                        color: #092C40;
                    }
                }

                .m-names {
                    display: flex;
                    align-items: center;
                    margin-left: 10px;

                    .m-name {
                        font-family: Roboto Medium;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        color: #020819;
                    }

                    .m-text {
                        font-family: Roboto Medium;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 14px;
                        color: #092C40;
                        opacity: 0.5;
                        margin-top: 5px;
                    }
                }
            }

            .m-right {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .m-value {
                    font-family: Roboto Bold;
                    font-style: normal;
                    font-weight: Medium;
                    font-size: 16px;
                    line-height: 19px;
                    color: #020819;
                }

                .m-total-value {
                    font-family: Roboto Bold;
                    font-style: normal;
                    font-weight: Black;
                    font-size: 12px;
                    line-height: 14px;
                    color: #169D8F;
                }
            }
        }
    }
}