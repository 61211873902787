.report-details-grid-1 {
    display: grid;
    grid-template-columns: minmax(150px, 1.2fr) minmax(120px, 1.2fr) minmax(120px, 1.5fr) minmax(150px, 1.5fr)  minmax(100px, 1.2fr) minmax(200px, 1.5fr);
}

.report-details-header {
    background-color: #FAFAFA;
}

// .report-details-list {
//     max-height: calc(100vh - 170px);
//     overflow-y: scroll;
// }

.report-details-grid-2 {
    display: grid;
    grid-template-columns: minmax(150px, 1.2fr) minmax(150px, 1.5fr) minmax(100px, 1fr)  minmax(100px, 1fr) minmax(100px, 1.2fr)  minmax(150px, 1fr);
}

.report-d-list {
    overflow-y: scroll;
    max-height: calc(100vh - 200px);
}

.report-org-dd {
    min-width: 240px;
    width: 240px;
}


.small-input {
    height: 32px;
    width: 125px;
    border-radius: 12px;
    font-size: 14px;
    border: 1.5px solid rgba(181, 185, 193, 0.3);
    color: #053149;
    font-family: Lato Regular;  
    font-size: 16px;
    outline: none;
    padding-left: 16px;
    padding-right: 16px;
  }