.nft-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-right: 24px;

  button {
    width: 112px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #24CBB1;
    font-weight: 700;
    background: none;
    border-radius: 10px;
    color: #24CBB1;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease-in;
  }

  button.save-action {
    background: #24CBB1;
    color: white;
  }

  button:hover {
    opacity: 0.8;
  }
}

.nft-details-list {
  overflow-y: scroll;
  max-height: calc(100vh - 150px);
}
