.collection-details {

    .nft-cards {
        max-height: calc(100vh - 180px);
    }

    .nft-card {
        background: #F6F6F6;
        box-shadow: none;
    }
}

.collection-description {
    overflow-y: scroll;
    max-height: calc(100vh - 610px);
}

.collection-image {
    width: 306px;
    border-radius: 12px;
    object-fit: cover;
}

.collection-info {
    width: 354px;

    .collection-info-details {
        overflow-y: scroll;
        max-height: calc(100vh - 215px);
    }

    .integration-icon {
        height: 16px;
    }

    .collection-info-edit {
        overflow-y: scroll;
        max-height: calc(100vh - 300px);
        .mc-select { 
            .selected-icon,
            .option-icon {
              height: 18px;
            }
          }
    }
}