.icon-input {
    height: 48px;
    border: 1.5px solid rgba(181, 185, 193, 0.3);
    border-radius: 16px;
    padding-left: 16px;
    padding-right: 16px;

    img {
        height: 20px;
    }

    input {
        border: none;
        color: #053149;
        font-family: Lato Regular;
        font-size: 16px;
        outline: none;
        margin-left: 16px;
    }

    input::placeholder {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #020819;
        opacity: 0.3;
    }
}

.icon-input:focus-within {
    border: 1.5px solid #32e5c5;
  }