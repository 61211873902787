.schema-attributes {
  height: calc(100vh - 190px);
  overflow-y: scroll;

  .schema-attributes-grid {
    display: grid;
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr) minmax(
        100px,
        1fr
      );
  }
}

.schema-edit-btn {
  height: 32px;
  width: 112px;
  background: rgba(36, 203, 177, 0.1);
  border-radius: 10px;

  svg {
    path {
      fill: #24cbb1;
    }
  }
}

.edit-schema-attribute {
  padding: 16px;

  .add-new-attribute {
    background: rgba(246, 246, 246, 0.5);
    border: 1px dashed rgba(181, 185, 193, 0.3);
    border-radius: 12px;
    padding: 22px;
  }
  .attribute-item {
    display: grid;
    grid-template-columns:
      40px minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr)
      40px;
    background: #f6f6f6;
    border-radius: 12px;
    padding: 12px 0px;

    .input {
      height: 32px;
      border-radius: 12px;
      font-size: 14px;
    }

    .mc-select {
      .organisations-border {
        background-color: white !important;
      }
    }

    .remove-icon {
      path {
        fill: #fb3535;
      }
    }
  }
}
