.ci-consigneds {
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
}

.ci-cons-grid {
    display: grid;
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 0.8fr) minmax(100px, 1fr)  minmax(100px, 1fr) minmax(100px, 1fr) ;
}

.ci-cons-header {
    background-color: #FAFAFA;
}
