.campaign-details {
  .campaign-details-l {
    width: 350px;

    .campaign-details-edit {
      max-height: calc(100vh - 315px);
      overflow: scroll;

      .input {
        padding: 14px 16px;
      }

      .mc-select {
        width: 100%;
        height: 100%;

        .selected-icon,
        .option-icon {
          height: 18px;
        }
      }

      .end-date-selector {
        .date-selector {
          right: 0;
          left: unset;
        }
      }
    }
  }

  .social-btn {
    background: #f6f6f6;
    border-radius: 10px;
    width: 112px;
  }
}
.ci-icon {
  height: 18px;
}
