.dashboard {
    flex: 1;
    display: flex;
    flex-direction: column;

    .dashboard-analytics {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-items: stretch;

        .analytics {
            flex: 1;
        }
    }

    .dashboard-content {
        flex: 2.5;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-items: stretch;

        .chart {
            flex: 2;
        }

        .top-merchants {
            flex: 1;
        }
    }

    .sale-selectors {
        .loading {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
          }
          .unit-selectors {
            background: rgb(170, 186, 183, 0.1);
            border-radius: 8px; 
            height: 32px;
        
            .unit-selector {
              width: 64px;
              border-radius: 6px; 
              height: 24px;
            }
          }
        
          .date-selector-btn {
            width: 32px;
          }

          .nav-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 32px;
            user-select: none;
            border-radius: 8px;
            background: rgb(170, 186, 183, 0.1);
            margin-left: 16px;
            border: 1px solid #F6F6F6;
          }
        
          .dashboard-lead {
            height: unset;
        
            .pagination-content {
              margin: 0;
              background: #fafcfd;
        
              .selected {
                background: #053149;
              }
        
              .menu-container {
                right: -20px;
        
                &:before {
                  left: unset;
                  right: 28px;
                }
              }
            }
          }
    }
}