.add-template-popup {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color:  rgba(11, 22, 35, 0.49);
  top: 0;
  left: 0;
  user-select: none;
  outline: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;

  .add-template-popup-content {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid rgba(240, 246, 249, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 6px 40px rgba(108, 129, 140, 0.1);
    border-radius: 16px;
    outline: 0;
    width: 424px;
    margin-top: 64px;
    position: relative;

    .popup-title {
      font-weight: 700;
      color: black;
    }

    .add-template-template-image {
      height: 40px;
      width: 40px;
      object-fit: cover;
      border-radius: 8px;
    }

    .add-template-template {
      border: 1px solid rgba(181, 185, 193, 0.5);
      border-radius: 12px;
    }
  }

  .add-t-radio {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    border: 1px solid rgba(181, 185, 193, 0.5);
    border-radius: 100px;

    .add-t-radio-selected {
      width: 8px;
      height: 8px;
      min-width: 8px;
      min-height: 8px;
      background: #24CBB1;
      border-radius: 100px;
    }
  }

  .add-t-templates {
    max-height: 330px;
    overflow-y: scroll;
  }

  .edit-t-id {
    border-radius: 12px;
  }

  .edit-t-search {
    background: url(../../../assets/images/search.svg) no-repeat scroll 5px 3px;
    padding-left:35px;
    border-radius: 12px;
  }
}
