.column {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 1px 24px;
    border-right: solid 1px rgba(181, 185, 193, 0.3);
}

.column:last-child {
    border-right: none;
}

.grow {
    flex: 1 1 400px;
    flex-shrink: 2;
}

.campaing {
    border-bottom: 1px solid rgba(181, 185, 193, 0.3);
    padding: 4px 0;
    display: grid;
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 2fr) minmax(250px, 1.5fr) minmax(100px, 1fr);

    .campaign-item {
        padding: 10px 24px;
    }
}