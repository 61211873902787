.campaign-achievements {
    height: calc(100vh - 255px);
}

.camp-ach-grid {
    display: grid;
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 0.5fr) minmax(100px, 0.5fr)  minmax(100px, 0.5fr) minmax(100px, 1fr) minmax(100px, 0.6fr) minmax(100px, 1.2fr);
}

.camp-ach-header {
    background-color: #FAFAFA;
}

.camp-retirements { 
    height: calc(100vh - 375px);
    overflow-y: scroll;
}

.campaign-list-camp {
    height: calc(100vh - 255px);
    overflow-y: hidden;
}

.camp-campaign-list {
    height: calc(100vh - 400px);
    overflow-y: scroll;

    .infinite-scroll-component__outerdiv {
        height: calc(100% - 10px);
      }
}

.camp-campaign-grid {
    display: grid;
    grid-template-columns: minmax(100px, 1.2fr)  minmax(100px, 0.8fr) minmax(100px, 1fr) minmax(100px, 0.8fr) minmax(100px, 1.5fr);
}

.camp-campaign-list {
    max-height: calc(100vh - 400px);
    overflow-y: scroll;
}

.achievement-menu  {
    right: 14px;
    top: 10px;
}